<template>
  <div class="mt-5">
    <v-row no-gutters>
      <v-col cols="12" md="4" sm="4" lg="4" xl="4">
        <v-toolbar dense elevation="0">
          <div class="mr-5"><h3>Produced</h3></div>
        </v-toolbar>
      </v-col>
      <v-spacer />
      <!-- <v-col cols="12" md="2">
        <v-select
          v-model="QRStatus"
          :items="QRStatusItems"
          dense
          label="Sort by Country"
          outlined
          item-text="text"
          item-value="value"
        >
        </v-select>
      </v-col> -->
      <v-col cols="12" md="5" sm="5" lg="5" xl="5">
        <v-toolbar dense class="elevation-0">
          <v-text-field
            v-model="search"
            label="search"
            class="mr-2 mt-5"
            dense
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-tooltip bottom right color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="generateQRDialog = true"
                small
                v-on="on"
                class="mr-2 gradient-bg white--text"
              >
                <v-icon dense>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span class="white--text">Generate QR</span>
          </v-tooltip>
          <v-btn @click="exporttoexcel()" small class="gradient-bg white--text">
            <v-icon dense class="mr-2">mdi-export</v-icon>Export
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-4">
      <v-col cols="12" md="12" sm="12">
        <v-data-table
          :headers="QRData"
          :items="QRItems"
          dense
          class="overflow-y-auto overflow"
          :fixed-header="fixed"
          :loading="isLoading"
          :height="height"
          :search="search"
          :items-per-page="100"
        >
          <template v-slot:[`item.qr_id_Copy`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="
                    () =>
                      doCopy(
                        `https://www.prezence.app/Apps/myprezence?qr_id=${item.qr_id}`
                      )
                  "
                  v-on="on"
                  v-bind="attrs"
                  class="gradient-btn"
                  >mdi-content-copy</v-icon
                >
              </template>
              <span class="white--text">{{
                `https://www.prezence.app/Apps/myprezence?qr_id=${item.qr_id}`
              }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.qr_created_on`]="{ item }">
            <span>{{ get_date(item.qr_created_on) }}</span>
          </template>
          <template v-slot:[`item.qr_id`]="{ item }">
            <div>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    class="gradient-btn"
                    @click="displayQR(item)"
                    >mdi-qrcode</v-icon
                  >
                </template>
                <span class="white--text">View QR</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">
              No QR's have been Produced yet
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Overlay :overlay="overlay" />
    <SnackBar :SnackBarComponent="SnackBarComponent" />
    <ViewQR
      :userDetailsObj="userDetailsObj"
      :viewQRDialog="viewQRDialog"
      @close_view_qr_dialog="viewQRDialog = false"
    />
    <GenerateQR
      @close_dialog="generateQRDialog = false"
      :generateQRDialog="generateQRDialog"
      @get_qrs="get_qr"
    />
  </div>
</template>

<script>
import Overlay from "@/components/Overlay.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { ListQrsDifferentStates } from "@/graphql/queries.js";
import ViewQR from "@/components/Dialogs/QRs/ViewQR.vue";
import GenerateQR from "@/components/Dialogs/QRs/GenerateQR.vue";
import { parseTime } from "@/utils";

export default {
  components: {
    Overlay,
    SnackBar,
    GenerateQR,
    ViewQR,
  },
  data: () => ({
    generateQRDialog: false,
    viewQRDialog: false,
    isLoading: false,
    userDetailsObj: {},
    QRData: [
      { text: "QR ID", width: "", value: "prznce_qr_count" },
      { text: "QR", width: "", value: "qr_id" },
      { text: "copy", width: "", value: "qr_id_Copy" },
      { text: "Created By", width: "", value: "qr_created_by" },
      { text: "Created On", width: "", value: "qr_created_on" },
    ],
    QRItems: [],
    search: "",
    fixed: true,
    height: 0,
    overlay: false,
    SnackBarComponent: {},
    QRStatus: "IN",
    QRStatusItems: [
      { text: "IN", value: "IN" },
      { text: "US", value: "US" },
      { text: "UAE", value: "UAE" },
    ],
  }),
  mounted() {
    this.height = window.innerHeight - 190;
    this.get_qr_list("PRODUCED");
  },
  methods: {
    exporttoexcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = ["QR ID", "QR Unique ID"];
        const filterVal = ["qr_id", "prznce_qr_count"];
        const list = this.QRItems;
        list.forEach(function (findx) {
          if (findx.qr_id != undefined) {
            findx.qr_id =
              "https://www.prezence.app/Apps/myprezence?qr_id=" + findx.qr_id;
          }
        });
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "Produced QRs List",
        });
      });
      //
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    get_qr() {
      this.get_qr_list("PRODUCED");
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a.split(",")[0].toUpperCase();
    },
    displayQR(item) {
      this.userDetailsObj = item;
      this.viewQRDialog = true;
    },
    doCopy: function (urlString) {
      if (urlString) {
        this.$store.commit("snackbar/showMessage", {
          content: "QR URL Copied Successfully!",
          color: "green",
        });
        this.$copyText(urlString);
      }
    },
    async get_qr_list(qr) {
      this.QRItems = [];
      this.isLoading = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(ListQrsDifferentStates, {
            input: {
              qr_type: qr,
            },
          })
        );
        this.QRItems = result.data.ListQrsDifferentStates.data.Items;
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style>
</style>