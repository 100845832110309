var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4","lg":"4","xl":"4"}},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('div',{staticClass:"mr-5"},[_c('h3',[_vm._v("Produced")])])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5","lg":"5","xl":"5"}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":""}},[_c('v-text-field',{staticClass:"mr-2 mt-5",attrs:{"label":"search","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":"","right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 gradient-bg white--text",attrs:{"small":""},on:{"click":function($event){_vm.generateQRDialog = true}}},on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Generate QR")])]),_c('v-btn',{staticClass:"gradient-bg white--text",attrs:{"small":""},on:{"click":function($event){return _vm.exporttoexcel()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dense":""}},[_vm._v("mdi-export")]),_vm._v("Export ")],1)],1)],1)],1),_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"overflow-y-auto overflow",attrs:{"headers":_vm.QRData,"items":_vm.QRItems,"dense":"","fixed-header":_vm.fixed,"loading":_vm.isLoading,"height":_vm.height,"search":_vm.search,"items-per-page":100},scopedSlots:_vm._u([{key:"item.qr_id_Copy",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"gradient-btn",attrs:{"small":""},on:{"click":function () { return _vm.doCopy(
                      ("https://www.prezence.app/Apps/myprezence?qr_id=" + (item.qr_id))
                    ); }}},'v-icon',attrs,false),on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(("https://www.prezence.app/Apps/myprezence?qr_id=" + (item.qr_id))))])])]}},{key:"item.qr_created_on",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.get_date(item.qr_created_on)))])]}},{key:"item.qr_id",fn:function(ref){
                    var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"gradient-btn",attrs:{"small":""},on:{"click":function($event){return _vm.displayQR(item)}}},on),[_vm._v("mdi-qrcode")])]}}],null,true)},[_c('span',{staticClass:"white--text"},[_vm._v("View QR")])])],1)]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text"},[_vm._v(" No QR's have been Produced yet ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{staticClass:"gradient-bg2 white--text",attrs:{"value":true}},[_vm._v(" No records found! ")])]},proxy:true}],null,true)})],1)],1),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('SnackBar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}),_c('ViewQR',{attrs:{"userDetailsObj":_vm.userDetailsObj,"viewQRDialog":_vm.viewQRDialog},on:{"close_view_qr_dialog":function($event){_vm.viewQRDialog = false}}}),_c('GenerateQR',{attrs:{"generateQRDialog":_vm.generateQRDialog},on:{"close_dialog":function($event){_vm.generateQRDialog = false},"get_qrs":_vm.get_qr}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }