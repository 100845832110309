<template>
  <div>
    <v-dialog v-model="generateQRDialog" persistent width="500px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-toolbar-title>Generate QR</v-toolbar-title>
        <v-spacer />
        <v-btn @click="close_dialog()" text
          ><v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="overflow--hidden">
        <v-form ref="form"> 
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <v-select
                v-model="qr"
                :rules="qrrules"
                dense
                outlined
                class="mx-3 mt-3"
                :items="qr_items"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-card-text align="center">
          <v-btn
            :loading="Loading"
            @click="validate_generate_qr()"
            class="gradient-bg white--text"
            >Generate QR</v-btn
          >
        </v-card-text>
      </v-card>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
    </v-dialog>
  </div>
</template>

<script>
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { GenerateQr } from "@/graphql/mutations.js";

export default {
  props: {
    generateQRDialog: Boolean,
  },
  components: {
    SnackBar,
  },
  data: () => ({
    qr: "",
    Loading: false,
    qrrules: [(v) => !!v || "Select number of QR(s) to be Generated"],
    qr_items: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "99", value: 99 },
    ],
    SnackBarComponent: {},
  }),
  methods: {
    validate_generate_qr() {
      if (this.$refs.form.validate()) {
        this.generate_qr();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Enter Valid Inputs",
        };
      }
    },
    async generate_qr() {
      this.Loading = true;
      try {
        var inputParams = {
          number_of_qrs: this.qr,
          user_email_id: this.$store.getters.get_useremail,
        };
        for (const [key, value] of Object.entries(inputParams)) {
          if (value == "") delete inputParams[key];
        }
        await API.graphql(
          graphqlOperation(GenerateQr, {
            input: inputParams,
          })
        ).then((res) => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: JSON.parse(res.data.GenerateQr),
          };
          this.$refs.form.reset();
          this.Loading = false;
          this.$emit("get_qrs");
          this.close_dialog();
        });
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.Loading = false;
      }
    },
    close_dialog() {
      this.$emit("close_dialog");
    },
  },
};
</script>

<style>
</style>